import React, { useState, useEffect } from "react";
import styles from "./ReportBuilder.module.scss";
import LoadingAnimation from "../../HATV/LoadingAnimation/LoadingAnimation";
import ReportCard from "../ReportCard/ReportCard";
import { useTranslation } from "react-i18next";
import ToastMessage from "../../common/ToastMessage/ToastMessage";

interface ReportBuilderProps {
  onClickCreateNewReportHandler: () => void;
}

const ReportBuilder: React.FC<ReportBuilderProps> = ({
  onClickCreateNewReportHandler = () => { },
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
      setToastMessage("Reports Successfully Generated");
      setShowToast(true);
    }, 3000);

    const toastTimer = setTimeout(() => {
      setShowToast(false);
    }, 6000);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(toastMessage);
    }
  }, []);
  return (
    <>
      {!isLoading ? (
        <div
          className={`report_builder_wrapper ${styles.report_builder_wrapper}`}
        >
          <ReportCard onClickCreateNewReport={onClickCreateNewReportHandler} />
          {showToast && (
            <ToastMessage
              message={toastMessage}
              subText=""
              toastType="success"
              showToast={showToast}
              setShowToast={setShowToast}
            />
          )}
        </div>
      ) : (
        <>
          <div className={styles.initial_text}>{t("creating_report")}</div>
          <LoadingAnimation isIcon={false} customText={t("analyzing_data")} />
        </>
      )}
    </>
  );
};

export default ReportBuilder;
