import dropdownArrowLight from "../../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../../assets/images/DarkTheme/down_arrow_dark.svg";
import React, { useState } from 'react';
import isEmpty from "lodash/isEmpty";
import styles from './InsightAI.module.scss';
import Icon from '../../Icon/Icon';
import { useAppSelector } from '../../../../redux/hooks';
import Tooltip from "../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface AIInsightProps {
  updateDate?: string;
  content: any;
  showDropdown: boolean; // Determines if the dropdown icon should be shown
  isDropdownEnabled?: boolean; // Controls if the content can be toggled
  flowerIconSize?: number;
  infoIconSize?: number;
  title?: string;
  titleSize?: number;
  className?: string;
}

const AIInsight: React.FC<AIInsightProps> = ({
  updateDate,
  content,
  showDropdown,
  isDropdownEnabled = false, // Default to false
  flowerIconSize = 25,
  infoIconSize = 12,
  title,
  titleSize = 14,
  className,
}) => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow = currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  const { t } = useTranslation();

  const handleDropdownClick = () => {
    if (isDropdownEnabled) {
      setIsContentVisible(prevState => !prevState);
    }
  };

  return (
    <div className={`ai_insight_wrapper ${styles.ai_insight_wrapper} ${className}`}>
      <div className={styles.ai_insight_header}>
        <div className={styles.ai_title_wrapper}>
          <Icon
            name='ai_flower_logo'
            size={flowerIconSize}
            color={currentTheme === "light" ? "#1176A9" : "#B2F9FF"}
            className={styles.ai_flower_logo}
          />
          <span
            className={`ai_title ${styles.ai_title}`}
            style={{ fontSize: `${titleSize}px` }}
          >
            {title ? t(title) : t("ai_insight")}
          </span>
          <Tooltip content={t("aiInsightTooltip")} width="257px" height="48px" top="-375%" left="2000%">
            <Icon
              name="info_icon"
              size={infoIconSize}
              color={currentTheme === "light" ? "#2c2c2c" : "#AEAEB2"}
              className={styles.info_icon}
            />
          </Tooltip>
        </div>

        <div className={`update_date_wrapper ${styles.update_date_wrapper}`}>
          {/* <span className={`update_date ${styles.update_date}`}>{t("updated")} {updateDate}</span> */}
          {showDropdown && (
            <img
              src={dropdownArrow}
              alt="Dropdown Arrow"
              className={`dropdown_icon ${styles.dropdown_icon} ${isContentVisible ? styles.rotate : ""}`}
              onClick={handleDropdownClick}
              style={{
                cursor: isDropdownEnabled ? 'pointer' : 'default',
              }}
            />
          )}
        </div>
      </div>

      {/* Show content directly if showDropdown is false */}
      {!showDropdown && isDropdownEnabled && (
        <div className={`markdown_styled ${styles.markdown_styled} ${styles.ai_content_wrapper}`}>
          {/* <p className={`ai_content ${styles.ai_content}`}>{content}</p> */}
          {isEmpty(content) ? (
            <p className={`ai_content ${styles.ai_content}`}>{t("noDataAvailable")}</p>
          ) : (
            <ReactMarkdown 
              className={`ai_content ${styles.ai_content}`} 
              remarkPlugins={[remarkGfm]}
            >
              {content}
            </ReactMarkdown>
          )}
          {showDropdown && (
            // <div className={`thumbs_wrapper ${styles.thumbs_wrapper}`}>
            {/* <Icon 
                name="thumbs_Up_Icon"
                size={16}
                color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
                className={`thumbs_icon ${styles.thumbs_icon}`}
              />
              <Icon 
                name="thumbs_Down_Icon"
                size={16}
                color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
                className={`thumbs_icon ${styles.thumbs_icon}`}
              /> */}
            // </div>
          )}
        </div>
      )}

      {/* Show content based on the logic if showDropdown is true */}
      {showDropdown && isDropdownEnabled && isContentVisible && (
        <div className={`ai_content_wrapper markdown_styled ${styles.markdown_styled} ${styles.ai_content_wrapper}`}>
          {/* <p className={`ai_content ${styles.ai_content}`}>{content}</p> */}
          {isEmpty(content) ? (
            <p className={`ai_content ${styles.ai_content}`}>{t("noDataAvailable")}</p>
          ) : (
            <ReactMarkdown 
              className={`ai_content ${styles.ai_content}`} 
              remarkPlugins={[remarkGfm]}
            >
              {content}
            </ReactMarkdown>
          )}
          {/* <div className={`thumbs_wrapper ${styles.thumbs_wrapper}`}>
            <Icon 
              name="thumbs_Up_Icon"
              size={16}
              color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
              className={`thumbs_icon ${styles.thumbs_icon}`}
            />
            <Icon 
              name="thumbs_Down_Icon"
              size={16}
              color={currentTheme === "light" ? "#707070" : "#AEAEB2"}
              className={`thumbs_icon ${styles.thumbs_icon}`}
            />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default AIInsight;
