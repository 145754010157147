import React, { useState } from 'react';
import styles from './ReportCard.module.scss';
import { useTranslation } from 'react-i18next';
import AIInsight from '../../common/AGCDetailsTile/InsightAI/InsightAI';
import Icon from '../../common/Icon/Icon';
import { useAppSelector } from '../../../redux/hooks';
import downloadLogoLight from '../../../assets/images/LightTheme/report_download_icon.svg';
import downloadLogoDark from '../../../assets/images/DarkTheme/report_download_icon.svg';
import Modal from '../../common/Modal/Modal';


interface ReportCardProps {
  onClickCreateNewReport: () => void;
}

const ReportCard: React.FC<ReportCardProps> = ({
  onClickCreateNewReport = () => { },
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const download_icon = currentTheme === "light" ? downloadLogoLight : downloadLogoDark;
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const data = "Lorem ipsum dolor sit amet consectetur. Mauris sociis lacus non maecenas tristique maecenas urna. Aliquam fringilla eget orci gravida nunc ac congue. Nunc eu adipiscing tristique magna. Ornare porttitor proin platea in cras vestibulum facilisi ac. Lorem ipsum dolor sit amet consectetur. Mauris sociis lacus non maecenas tristique maecenas urna. Aliquam fringilla eget orci gravida nunc ac congue. Nunc eu adipiscing tristique magna. Ornare porttitor proin platea in cras vestibulum facilisi ac. "

  const handlePreviewClick = () => {
    setIsPreviewVisible(true);
  };
  const handleClosePreview = () => {
    setIsPreviewVisible(false);
  };

  return (
    <div className={`card_wrapper ${styles.card_wrapper}`}>
      <div className={styles.header_wrapper}>
        <div className={styles.header}>Report Builder</div>
        <button className={`create_report_button ${styles.create_report_button}`} onClick={onClickCreateNewReport}>
          <div className={`create_text ${styles.create_text}`}>
            <Icon 
              name='plus_white'
              size={14}
              color={currentTheme === "light" ? "#2c2c2c" : "#D8D8D9"}
              className={styles.plus_icon}
            />
            <span>{t("create_report")} </span>
          </div>
        </button>
      </div>
      <div className={`report_card ${styles.report_card}`}>
        <div className={`report_header ${styles.report_header}`}>
          <div className={styles.abo_details}>
            <div className={`report_title_wrapper ${styles.report_title_wrapper}`}>
              <div className={`report_title ${styles.report_title}`}>
                Cooper George <span className={styles.report_id}>#462567</span>
              </div>
            </div>
            <div className={`report_details ${styles.report_details}`}>
              <span className={`first_supporting_text ${styles.first_supporting_text}`}>GAR QC Tracking</span>
              <span className={`second_supporting_text ${styles.second_supporting_text}`}>|   Word Doc — 1.2 MB</span>
            </div>
          </div>

          <div className={styles.report_actions}>
            <div className={`preview_button ${styles.preview_button}`} onClick={handlePreviewClick} >{t("preview")}</div>
            <div className={`download_button ${styles.download_button}`}>
              <img
                src={download_icon}
                alt="Follow up arrow"
                className={styles.arrow_image}
                style={{ width: "40px", height: "40px" }}
              />
            </div>
          </div>
        </div>
        <div className={styles.ai_summary}>
          <AIInsight
            updateDate="08/19/2024"
            content={data}
            showDropdown={false}
            isDropdownEnabled={true}
            flowerIconSize={24}
            infoIconSize={12}
            title={"ai_summary"}
            titleSize={16}
            className={styles.report_insight}
          />
        </div>
      </div>
      <Modal showDrawer={isPreviewVisible} onClose={handleClosePreview}>
        <div className={styles.preview_content}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>{t('Report Preview')}</div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportCard;
