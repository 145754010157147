import React, { useEffect, useState } from "react";
import styles from "./ReportLandingPage.module.scss";
import AccountListDropDown from "../../common/Report/AccountListDropdown/AccountListDropDown";
import ReportBuilder from "../ReportBuilder/ReportBuilder";
import Button from "../../common/Button/Button";
import { useTranslation } from "react-i18next";
import { useLazyGetReportBulderAboListQuery } from "../../../redux/services/reportBuilderAPI";
import { useAppSelector } from "../../../redux/hooks";
import { isValidResponse } from "../../../utils/validateAPIResponse";
import { isEmpty } from "lodash";
import { useGetOptionsListQuery } from "../../../redux/services/optionMasterAPI";

const ReportLandingPage: React.FC = () => {
  const [isGenerateReport, setIsGenerateReport] = useState<boolean>(true);
  const [isReportTypeSelected, setIsReportTypeSelected] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isReportPage, setIsReportPage] = useState<boolean>(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const [reportAboList, setReportAboList] = useState<any>([]);
  const [reportTypeData, setReportTypeData]= useState<ReportType[]>([]);

  const onABOSelection = (items: string[]) => {
    setSelectedItems(items);
  };

  const { t } = useTranslation();

  const onBack = () => {
    setIsReportTypeSelected('');
    setIsGenerateReport(true);
    setIsReportPage(false);
  };

  const GenerateReportHandler = () => {
    setIsGenerateReport(false);
    setIsReportPage(true);
  };

  const handleClickCreateNewReport = () => {
    setIsReportTypeSelected('');
    setIsGenerateReport(true);
    setIsReportPage(false);
    setSelectedItems([]);
  };

  const [
    getReportBuilderABOList,
    { data: reportAboListData,
      isFetching: isLoadingGetReportsAboList,
      isError
    }] = useLazyGetReportBulderAboListQuery();


  const handleAPIError = () => {
    setIsAPIError(true);
  };

  useEffect(() => {
    if (isError) handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(reportAboListData)) {
      if (isValidResponse(reportAboListData?.statusCode)) {
        setReportAboList(reportAboListData?.body || []);
      } else {
        handleAPIError();
      }
    }
  }, [reportAboListData, isLoadingGetReportsAboList]);


  useEffect(() => {
    if (isReportTypeSelected && isReportTypeSelected !== '') {
      getReportBuilderABOList({
        // affiliateCode: localStorage?.getItem('userAffiliateCode') || '',
        reportType: isReportTypeSelected,
        payload: {
          keyword: ''
        }
      })
    }

  }, [isReportTypeSelected])
  const { data, isLoading, error } = useGetOptionsListQuery({
    applicationCode: "amw",
    optionMasterName: "report-reporttype",
  });

  useEffect(() => {
    const resData: ReportType[] = data?.body?.options;
    setReportTypeData(resData || []);

  }, [data]);

  const onABOSearch = (searchTerm: string) => {
    getReportBuilderABOList({
      reportType: isReportTypeSelected,
      payload: {
        keyword: searchTerm ? searchTerm : ''
      }
    })
  };

  return (
    <div className={`report_wrapper ${styles.report_wrapper}`}>
      {isReportPage ? (
        <ReportBuilder
          onClickCreateNewReportHandler={handleClickCreateNewReport}
        />
      ) : (
        <>
          <div className={styles.report_header_wrapper}>
            <div className={styles.heading_wrapper}>
              <div className={`report_heading ${styles.report_heading}`}>
                {t("report_builder")}
              </div>
              <div className={`report_subheading ${styles.report_subheading}`}>
                {isReportTypeSelected && isReportTypeSelected !== ''
                  ? t("select_account")
                  : t("select_report")}
              </div>
            </div>
            {isReportTypeSelected && isReportTypeSelected !== '' && (
              <div className={`nav_btn_wrapper ${styles.nav_btn_wrapper}`}>
                <Button
                  id="backButton"
                  onClick={onBack}
                  label={t("report_back_button")}
                  style="unfilled"
                />
                <Button
                  id="generateReportButton"
                  onClick={GenerateReportHandler}
                  isDisabled={selectedItems.length === 0}
                  label={t("generate_report")}
                  style="filled"
                />
              </div>
            )}
          </div>
          {isLoading ? 
              <div className={styles.card_wrapper}>
              {Array.from({ length: reportTypeData.length || 2 }).map((_, index) => (
                <div className={`${styles.card_loader} animate`} key={index}>
                  <div className={`${styles.title} animate`}></div>
                  <div className={`${styles.content_loader}`}>
                    <div className={`${styles.first_line} animate`}></div>
                    <div className={`${styles.first_line} animate`}></div>
                    <div className={`${styles.first_line} animate`}></div>
                  </div>
                </div>
              ))}
            </div>          
           : (
            !isLoading && !isReportTypeSelected ? (
              <div className={`card_container ${styles.card_container}`}>
                {reportTypeData.map((report: ReportType, index: number) => (
                  <div
                    key={index}
                    className={`card ${styles.card}`}
                    onClick={() => setIsReportTypeSelected(report?.code)}
                  >
                    <div className={`card_heading ${styles.card_heading}`}>
                      {report?.value}
                    </div>
                    <div className={`card_content ${styles.card_content}`}>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur. Congue quam sed ut eu sed. Duis tempor sagittis posuere et
                      ac lorem.
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.report_builder_section}>
                <AccountListDropDown onABOSelection={onABOSelection} reportAboListData={reportAboList} onABOSearch={onABOSearch} isLoadingGetReportsAboList={isLoadingGetReportsAboList} />
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default ReportLandingPage;
